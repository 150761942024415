import { Box } from "@mui/material";
import { AvailableLanguages, IModifiedValuesProps } from "@smartrr/shared/entities/CustomerPortalTheme";
import { IShopifyGetThemesResponse, getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";
import { defaultCustomerPortalThemeConfig } from "@smartrr/shared/themes/defaultThemeConfig";
import React, { useCallback, useEffect, useState } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useRestClient } from "@vendor-app/app/AdminRoute/components/auth/RestProviderWrapper";

import { TranslationsContainer } from "./components/TranslationsContainer";
import { useToast } from "../../_sharedComponents/Toast/ToastProvider";
import { Spinner } from "../components/elements/Spinner";

const LANGUAGE_SELECTION_OPTIONS = [
  {
    label: "English",
    value: AvailableLanguages.ENGLISH,
  },
  {
    label: "French",
    value: AvailableLanguages.FRENCH,
  },
  {
    label: "Spanish",
    value: AvailableLanguages.SPANISH,
  },
  {
    label: "Portuguese (Portugal)",
    value: AvailableLanguages.PORTUGUESE,
  },
];

export const AdminTranslationsRoute = () => {
  const restClient = useRestClient();
  const [isLoading, setIsLoading] = useState<boolean>(
    useSmartrrVendorSelector(state => state.customerPortalTheme.isLoading)
  );
  const { addToast } = useToast();
  const customerPortalTranslationOverrides =
    useSmartrrVendorSelector(state => state.customerPortalTheme.customerPortalTheme.translationOverrides) ??
    defaultCustomerPortalThemeConfig.translationOverrides;

  const [activeLanguage, setActiveLanguage] = useState<AvailableLanguages>(
    customerPortalTranslationOverrides?.activeLanguage
  );

  const [modifiedValues, setModifiedValues] = useState<Record<string, IModifiedValuesProps>>({});
  const [shopifyThemes, setShopifyThemes] = useState<IShopifyGetThemesResponse["themes"]>([]);
  const [mainThemeId, setMainThemeId] = useState<number>();

  const getThemes = useCallback(async () => {
    const res = await getShopifyThemes(restClient);
    if (res.type === "success") {
      setShopifyThemes(res.body.themes || []);
    }
  }, []);

  useEffect(() => {
    getThemes();
    getMainThemeId();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (customerPortalTranslationOverrides) {
      setModifiedValues(customerPortalTranslationOverrides[activeLanguage]);
    }
    // Setting a delay so radio buttons can be sorted in time + switching between languages is smoother
    async function setLoadDelay() {
      setIsLoading(false);
    }
    setLoadDelay();
  }, [customerPortalTranslationOverrides, activeLanguage]);

  const getMainThemeId = useCallback(async () => {
    const res = await getShopifyThemes(restClient);

    if (res.type === "success") {
      setShopifyThemes(res.body.themes || []);
    }

    if (res.type === "error" || !res.body.themes?.find(t => t.role === "main")) {
      addToast("Error getting main theme id");

      return;
    }

    const mainTheme = res.body.themes.find(t => t.role === "main")!;
    setMainThemeId(mainTheme.id);
  }, []);

  useEffect(() => {
    setActiveLanguage(customerPortalTranslationOverrides.activeLanguage);
  }, [customerPortalTranslationOverrides]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "10px",
        ".Polaris-Page": {
          width: "100%",
          padding: "0px",
        },
      }}
    >
      <TranslationsContainer
        customerPortalTranslationOverrides={customerPortalTranslationOverrides! ?? {}}
        activeLanguage={activeLanguage}
        setActiveLanguage={setActiveLanguage}
        modifiedValues={modifiedValues}
        setModifiedValues={setModifiedValues}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        themes={shopifyThemes}
        mainThemeId={mainThemeId!}
        languageSelectionOption={LANGUAGE_SELECTION_OPTIONS}
      />
    </Box>
  );
};
