import { AvailableLanguages } from "@smartrr/shared/entities/CustomerPortalTheme";
import { localizeDefault } from "@smartrr/shared/utils/sharedTranslations/localizeDefault";

export const isObject = (v: any) => v && typeof v === "object";

export const camelCaseToTitleCase = (str: string) => {
  const s = str.replaceAll(/([A-Z])/g, " $1");
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isOverwrittenFunc = (path: string, overrideText: string) => {
  function index(obj: any, i: string) {
    return obj[i];
  }
  const originalVal = path.split("__").pop()?.split(".").reduce(index, localizeDefault);
  return overrideText !== originalVal;
};

export const formatPath = (path: string, activeLanguage: AvailableLanguages) => `${activeLanguage}__${path}`;

export const helpText = {
  // "addressForm.zip": "Ex:'Zip code', 'Postcode', 'Postal code' ",
};
