// Libraries
import { Box, Stack, Switch } from "@mui/material";
import {
  Button,
  ContextualSaveBar,
  Icon,
  Layout,
  LegacyCard,
  Page,
  RadioButton,
  Text,
  TextField,
} from "@shopify/polaris";
import { ArrowDownMinor, ExternalSmallMinor, InfoMinor, LockMajor } from "@shopify/polaris-icons";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import {
  AvailableLanguages,
  IModifiedValuesProps,
  ITranslationOverrides,
  ThemeVersion,
} from "@smartrr/shared/entities/CustomerPortalTheme";
import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import { localizeDefault } from "@smartrr/shared/utils/sharedTranslations/localizeDefault";
import _, { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

// Components
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  loadCustomerPortalTheme,
  updateCustomerPortalTheme,
} from "@vendor-app/app/_state/actionCreators/customerPortalTheme";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import {
  QuickJumpWrapper,
  TranslationsLinkContainer,
} from "../../AdminSubscriberEngagementRoute/EmailSettings/components/libs/styles";
import { ApplyToThemeModal } from "../../AdminThemingRoute/components/ApplyToThemeModal";
import { isFeatureAvailable } from "../../components/authorization/featureAccess";
import {
  LearnMoreAboutLink,
  LearnMoreIconWrapper,
  LearnMoreLinkContainer,
} from "../../components/elements/styles";
import TranslationsImage from "../TranslationsNotAvailable.png";

// Utils & Helpers
import { camelCaseToTitleCase, formatPath, helpText, isObject, isOverwrittenFunc } from "../utils";

interface ITranslationContainerProps {
  customerPortalTranslationOverrides: ITranslationOverrides;
  activeLanguage: AvailableLanguages;
  setActiveLanguage: React.Dispatch<React.SetStateAction<AvailableLanguages>>;
  modifiedValues: Record<string, IModifiedValuesProps>;
  setModifiedValues: React.Dispatch<React.SetStateAction<Record<string, IModifiedValuesProps>>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  themes: ISmartrrShopTheme[];
  mainThemeId: number;
  languageSelectionOption: {
    label: string;
    value: AvailableLanguages;
  }[];
}

interface InputRowProps {
  obj: string | object;
  /** not to be confused with the key needed for .map() */
  objKey: string;
  path: string;
}

const StackWrapper = styled(Stack)`
  h2.Polaris-Text--headingMd :first-child {
    margin-bottom: 20px;
  }
  & .anchor {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
  }
`;

const NamespaceSection = styled(Stack)`
  .section-header {
    background-color: var(--p-color-bg-subdued);
    border-top: 1px solid #e1e3e5;
    border-bottom: 1px solid #e1e3e5;
    color: var(--p-color-text);
    font-size: 14px;
    font-weight: 500;
    /* height: 50px; */
    padding: 15px 20px;
    width: 100%;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 28;
    height: auto;
  }
  .section-inner {
    .section-titles {
      align-items: center;
      &:not(:first-child) {
        border-top: 1px solid #e1e3e5;
      }
      display: flex;
      height: 40px;
      padding: 0 40px;
      p {
        color: var(--p-color-text);
        font-size: 14px;
        font-weight: 500;
        width: 245px;
        height: max-content;
        :last-child {
          width: 29px;
        }
      }
    }
    .input-row {
      align-items: center;
      border-top: 1px solid #e1e3e5;
      display: flex;
      padding: 10px 20px 10px 40px;

      & .Polaris-TextField {
        width: 245px;
      }
    }
  }
`;

const NotAvaialableContainer = styled.div`
  display: flex;
`;

const NotAvaialableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  .Polaris-Icon {
    fill: #5c5f62;
    margin-left: 0px;
    margin-right: 10px;
  }
`;

export const TranslationsContainer = ({
  customerPortalTranslationOverrides,
  activeLanguage,
  setActiveLanguage,
  modifiedValues,
  setModifiedValues,
  isLoading,
  setIsLoading,
  themes,
  mainThemeId,
  languageSelectionOption,
}: ITranslationContainerProps) => {
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();
  const [showThemesModal, setShowThemesModal] = useState(false);
  const [disableDiscard, setDisableDiscard] = useState(false);

  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const customerPortalTheme = useSmartrrVendorSelector(state => state.customerPortalTheme.customerPortalTheme);
  const user = useSmartrrVendorSelector(state => state.auth.user);
  const isPasswordless = useSmartrrVendorSelector(
    state => state.customerPortalTheme.customerPortalTheme
  ).isPasswordless;

  const sortedLanguageSelection = useMemo(() => {
    setIsLoading(true);
    const x = languageSelectionOption.sort((a, b) => {
      return a.value === customerPortalTranslationOverrides.activeLanguage
        ? -1
        : b.value === customerPortalTranslationOverrides.activeLanguage
          ? 1
          : 0;
    });
    setIsLoading(false);
    return x;
  }, [customerPortalTranslationOverrides.activeLanguage, languageSelectionOption, setIsLoading]);

  const hasTranslationChanges = useMemo(() => {
    return (
      (!isEqual(modifiedValues, customerPortalTranslationOverrides[activeLanguage]) &&
        Object.keys(modifiedValues).length > 0) ||
      !isEqual(activeLanguage, customerPortalTranslationOverrides.activeLanguage)
    );
  }, [activeLanguage, customerPortalTranslationOverrides, modifiedValues]);

  const onSave = () => {
    setShowThemesModal(true);
    setDisableDiscard(false);
  };
  const onDiscard = () => {
    window.location.reload();
  };
  const onClose = useCallback(() => setShowThemesModal(false), []);

  const onApplyTranslationsToTheme = async (theme: ISmartrrShopTheme) => {
    const updatedOverrideObj: ITranslationOverrides = {
      ...customerPortalTranslationOverrides,
      activeLanguage,
      [activeLanguage]: modifiedValues,
    };
    await dispatch(
      updateCustomerPortalTheme({
        updatedThemeProperties: {
          themeName: customerPortalTheme.themeName ?? ThemeVersion.MODERN,
          translationOverrides: updatedOverrideObj,
        },
        themeId: theme.id ?? mainThemeId,
        addToast,
      })
    );

    setShowThemesModal(false);
    await dispatch(loadCustomerPortalTheme());
  };

  const modifyValuesHandler = useCallback(
    (path: string, enabled: boolean, overrideText: string) => {
      const key = formatPath(path, activeLanguage);
      const isOverwritten = isOverwrittenFunc(path, overrideText);
      // Removing all emojis
      const cleanedText = overrideText
        .replaceAll(
          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
          ""
        )
        .replaceAll(/\s+/g, " ")
        .trim();
      if (!enabled && !isOverwritten) {
        // Delete if state is back to normal
        setModifiedValues(prevState => ({
          ..._.omit(prevState, [key]),
        }));
      } else if (modifiedValues[key]) {
        // Update if already in obj
        const newObj: IModifiedValuesProps = {
          enabled,
          value: cleanedText,
        };
        if (!_.isEqual(newObj, modifiedValues[key])) {
          setModifiedValues(prevState => ({
            ...prevState,
            [key]: { ...newObj },
          }));
        }
      } else {
        // Create new property if none exists already
        setModifiedValues(prevState => ({
          ...prevState,
          [key]: { enabled, value: cleanedText },
        }));
      }
    },
    [activeLanguage, modifiedValues]
  );

  const activeLanguageTranslations = Object.entries(localizeDefault).map(([key, value]) => ({
    content: value,
    parent: key,
  }));

  if (!isFeatureAvailable(FeatureEnum.TRANSLATIONS, user, activePlan)) {
    return (
      <Page narrowWidth title="Translations">
        <LegacyCard>
          <NotAvaialableContainer>
            <img src={TranslationsImage} width="232" height="226" alt="Not available" />
            <NotAvaialableTextContainer>
              <Text variant="headingMd" as="h2">
                <Stack direction="row">
                  <Icon source={LockMajor} />
                  <span>Translations unavailable on current plan</span>
                </Stack>
              </Text>
              <p>Upgrade to Grow to translate your Customer Account Portal from English to French or Spanish.</p>
              <div>
                <Button primary url="/admin/smartrr-account">
                  📈 Upgrade to Grow
                </Button>
              </div>
            </NotAvaialableTextContainer>
          </NotAvaialableContainer>
        </LegacyCard>
      </Page>
    );
  }

  const InputRow = ({ obj, objKey, path }: InputRowProps) => {
    // Default values
    const { enabled, value } = { ...modifiedValues[formatPath(path, activeLanguage)] };

    const defaultValue = value ?? obj[objKey as keyof typeof obj];
    const defaultSwitchState = enabled ?? false;
    const [toggleEnabled, setToggleEnabled] = useState<boolean>(defaultSwitchState);
    const [overrideText, setOverrideText] = useState<string>(defaultValue);

    // 2s delay after input is entered then stored in modifiedValues
    useEffect(() => {
      const delayFunc = setTimeout(() => {
        if (toggleEnabled !== defaultSwitchState || overrideText !== defaultValue) {
          modifyValuesHandler(path, toggleEnabled, overrideText);
        }
      }, 1000);
      return () => clearTimeout(delayFunc);
    }, [defaultSwitchState, defaultValue, overrideText, path, toggleEnabled]);

    // Separate useEffect for when multiple toggle switches are enabled in succession
    useEffect(() => {
      if (toggleEnabled !== defaultSwitchState) {
        modifyValuesHandler(path, toggleEnabled, overrideText);
      }
    }, [toggleEnabled]);

    return (
      <Stack className="input-row" direction="row" spacing="20px">
        <TextField
          label=" "
          value={obj[objKey as keyof typeof obj] as string}
          onChange={() => null}
          disabled
          autoComplete="off"
        />
        <TextField
          label=" "
          value={toggleEnabled ? overrideText : (obj[objKey as keyof typeof obj] as string)}
          helpText={helpText[path as keyof typeof helpText] ?? ""}
          onChange={e => setOverrideText(e)}
          disabled={!toggleEnabled}
          autoComplete="off"
          max={300}
        />
        <Switch onClick={() => setToggleEnabled(!toggleEnabled)} size="small" defaultChecked={toggleEnabled} />
      </Stack>
    );
  };

  // Recursive function for rendering input fields
  const NamespaceInner = (obj: Record<string, string | object>, _pathKey?: string) => {
    const path = typeof obj.pathKey === "string" ? obj.pathKey : "";
    return (
      <React.Fragment>
        {Object.keys(obj.obj).map((key, index) => {
          const typedKey = key as keyof typeof obj.obj;
          if (isObject(obj.obj[typedKey]) && obj.obj[typedKey] !== null) {
            return <NamespaceInner obj={obj.obj[typedKey]} pathKey={`${path}.${key}`} />;
          }
          return <InputRow obj={obj.obj} key={index} objKey={key} path={`${path}.${key}`} />;
        })}
      </React.Fragment>
    );
  };

  return (
    <Page title="Translations">
      <Box
        sx={{
          marginBottom: "20px",
          h3: { fontSize: "14px", color: "var(--p-color-text-subdued)", lineHeight: "inherit" },
        }}
      >
        <h3>Use override toggles in the section below to input override specific text instances.</h3>
      </Box>
      <Layout>
        <Layout.Section>
          <StackWrapper direction="column" spacing="loose">
            {Boolean(hasTranslationChanges) && (
              <ContextualSaveBar
                message="Unsaved changes"
                saveAction={{ onAction: onSave, loading: isLoading, content: "Save changes", disabled: false }}
                discardAction={{ content: "Discard changes", onAction: onDiscard, disabled: disableDiscard }}
              />
            )}
            <ApplyToThemeModal
              open={showThemesModal}
              themes={themes}
              onClose={onClose}
              onSubmit={onApplyTranslationsToTheme}
            />
            <LegacyCard sectioned>
              <Text variant="headingXs" as="h3">
                CUSTOMER ACCOUNT PORTAL LANGUAGE
              </Text>
              <Text variant="bodyMd" as="span" color="subdued">
                Set the default language for your shop&apos;s customer account portal. Optional—use override
                toggles in the section below to input override specific text instances.
              </Text>
              <Stack mt={"20px"} width="max-content">
                {sortedLanguageSelection.map(({ value, label }) => (
                  <RadioButton
                    key={label}
                    label={label}
                    checked={activeLanguage === value}
                    onChange={() => setActiveLanguage(value)}
                    name={label}
                  />
                ))}
              </Stack>
            </LegacyCard>
            <LegacyCard>
              <LegacyCard.Section>
                <Text variant="headingMd" as="h2">
                  Overrides
                </Text>
              </LegacyCard.Section>
              <React.Fragment>
                {activeLanguageTranslations && modifiedValues ? (
                  <React.Fragment>
                    {activeLanguageTranslations.map(({ parent, content }, index) => {
                      // Hiding our display utils from being displayed to users
                      // Also hiding passwordless values if passwordless is disabled
                      if (!isPasswordless && parent === "passwordlessLogin") {
                        return <React.Fragment />;
                      }

                      return (
                        <NamespaceSection key={index}>
                          <span id={parent} className="anchor"></span>
                          <div className="section-header">{camelCaseToTitleCase(parent)}</div>
                          <div className="section-inner">
                            <Stack direction="row" className="section-titles" spacing="20px">
                              <p>Original (translated) text </p>
                              <p>Override text</p>
                              <p>OFF/ON</p>
                            </Stack>
                            <Stack>
                              <NamespaceInner obj={content} pathKey={parent} />
                            </Stack>
                          </div>
                        </NamespaceSection>
                      );
                    })}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            </LegacyCard>
          </StackWrapper>
        </Layout.Section>
        <Layout.Section secondary>
          <QuickJumpWrapper>
            <LegacyCard
              title={
                <div className="card-heading">
                  <Text variant="headingMd" as="h2">
                    <Icon source={ArrowDownMinor} color="base" />
                    Quick Jump
                  </Text>
                </div>
              }
            >
              <div className="inner">
                <ul>
                  {Object.keys(localizeDefault).map(key => {
                    if (!isPasswordless && key === "passwordlessLogin") {
                      return <React.Fragment />;
                    }

                    return (
                      <li key={key}>
                        <a href={`#${key}`}>{camelCaseToTitleCase(key)}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </LegacyCard>
            <TranslationsLinkContainer>
              <Icon source={InfoMinor} color="highlight" />
              <LearnMoreLinkContainer>
                Learn more about&nbsp;
                <LearnMoreAboutLink
                  href="https://help.smartrr.com/docs/support/admin-portal/how-can-i-edit-the-copy-language-of-my-stores-account-portal-translations"
                  rel="noreferrer"
                  target="_blank"
                >
                  translations
                  <LearnMoreIconWrapper>
                    <Icon source={ExternalSmallMinor} color="base" />
                  </LearnMoreIconWrapper>
                </LearnMoreAboutLink>
              </LearnMoreLinkContainer>
            </TranslationsLinkContainer>
          </QuickJumpWrapper>
        </Layout.Section>
      </Layout>
    </Page>
  );
};
